import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ScrollDown from "./scroll-down"
import { UserInterfaceContext } from "../context/user-interface"

const NewWelcome: React.FC = () => {
  const { firstname } = React.useContext(UserInterfaceContext)
  return (
    <section className="relative lg:min-h-[100vh]">
      <StaticImage
        alt="Welcome to Open House"
        src="../images/01_Welcome_Option_1600x900_WhiteBottom.png"
        loading="eager"
        className="fluid !hidden lg:!block absolute lg:min-h-[calc(100vh_-_105px)]"
        width={2500}
      />
      <StaticImage
        alt=""
        width={1200}
        src="../images/1a_LandingPage_BKG_480x854_V01.jpg"
        loading="eager"
        className="lg:!hidden h-[400px]"
        imgClassName="lg:!hidden absolute h-[400px]"
      />
      <div className="z-50 absolute bottom-0 w-full">
        <div className="container content">
          <ScrollDown />
        </div>
      </div>
      <div className="w-full absolute top-0">
        <div className="container content mt-12 lg:mt-24 flex flex-col lg:min-h-[calc(100vh-105px-6rem)]">
          <h3 className="text-2xl xl:text-4xl 2xl:text-5xl text-white leading-tight xl:leading-tight 2xl:leading-normal">
            Hi{firstname ? ` ${firstname}` : ``}!<br />
            Welcome to
          </h3>
          <h2 className="text-[5rem] lg:text-[10rem] xl:text-[12rem] 2xl:text-[14rem] 3xl:text-[18rem] leading-[4rem] lg:leading-[8rem] xl:leading-[10rem] 2xl:leading-[12rem] 3xl:leading-[16rem] mt-4 lg:mt-0 uppercase font-bold text-white">
            Virtual
            <br /> Open
            <br /> House
          </h2>
        </div>
      </div>
    </section>
  )
}

export default NewWelcome
