import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import classNames from "../utils/classnames"
import agendaKeyToTimeRange from "../utils/agenda-key-time-range"
import Modal from "./modal"
import ProgramLookup from "./program-lookup"
import { StaticImage } from "gatsby-plugin-image"

const Agenda: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AGENDA_IMAGES {
      services: allAgendaJson(filter: { type: { eq: "services" } }) {
        nodes {
          jsonId
          title
          startTime
          endTime
          description
          modalContent
        }
      }
      academics: allAgendaJson(filter: { type: { eq: "academics" } }) {
        nodes {
          jsonId
          title
          startTime
          endTime
          description
          modalContent
        }
      }
    }
  `)
  console.log({ data })

  // group data.academics.nodes by startTime and endTime
  const groupedAcademics = data.academics.nodes.reduce((acc, curr) => {
    const key = `${curr.startTime}-${curr.endTime}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(curr)
    return acc
  }, {})

  // group data.services.nodes by startTime and endTime
  const groupedServices = data.services.nodes.reduce((acc, curr) => {
    const key = `${curr.startTime}-${curr.endTime}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(curr)
    return acc
  }, {})

  const servicesTimeBackgrounds = [`900-910`, `1530-1600`]
  const bgColors = {
    "1000-1100": { heading: `bg-gray`, box: `bg-[rgba(51,63,72,0.05)]` },
    "1130-1230": { heading: `bg-orange`, box: `bg-[rgba(179,89,0,0.05)]` },
    "1300-1400": { heading: `bg-blue`, box: `bg-[#F5F9FC]` },
    "1430-1530": { heading: `bg-blue`, box: `bg-[#F5F9FC]` },
    "900-1600": { heading: `bg-button`, box: `bg-[rgba(81,131,14,0.05)]` },
  }
  console.log({ groupedAcademics, groupedServices })
  return (
    <section id="career-match">
      <div className={classNames(`relative isolate overflow-hidden`)}>
        <StaticImage
          src="../images/GradientBanner.png"
          alt=""
          className={classNames(`!absolute top-0 left-0 h-full w-full z-[-1]`)}
        />
        <div className="content container mx-auto mt-8">
          <div className="max-w-lg">
            <StaticImage src="../images/ArrowIcon_Agenda-01.png" alt="" />
          </div>
        </div>
        <h3 className="text-white text-3xl lg:text-2xl container content py-8">
          <span className="block text-3xl mb-4">
            Get to know more about our services and programs. Ask questions in
            our live interactive sessions. Links to join the sessions will
            appear on the day of the event (Jan. 27) beginning at 9 a.m.
          </span>
          <span className="block text-2xl">
            All session times are in Eastern Standard Time (EST).
          </span>
        </h3>
      </div>

      <div className="content container my-16">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="lg:col-span-1 lg:my-0">
            <h3 className="text-blue text-2xl mb-4">
              Welcome and student services
            </h3>
            <div className="flex flex-col">
              {Object.keys(groupedServices).map(key => (
                <Fragment key={key}>
                  <h4
                    className={classNames(
                      `bg-blue text-white font-semibold px-4 py-2`
                    )}
                  >
                    {agendaKeyToTimeRange(key)}
                  </h4>
                  <div
                    className={classNames(
                      `p-4 flex gap-4`,
                      servicesTimeBackgrounds.includes(key)
                        ? `bg-[#F5F9FC]`
                        : ``
                    )}
                  >
                    {groupedServices[key].map(node => (
                      <div key={node.jsonId} className="flex-1 w-1/2">
                        <h5 className="text-xl leading-tight mb-2 font-semibold text-blue">
                          {node.title}
                        </h5>
                        <ReactMarkdown className="markdown leading-tight">
                          {node.description}
                        </ReactMarkdown>
                        {node.modalContent && (
                          <Modal
                            opener={
                              <button className="italic underline mt-4">
                                Learn MORE
                              </button>
                            }
                          >
                            <ReactMarkdown className="markdown">
                              {node.modalContent}
                            </ReactMarkdown>
                          </Modal>
                        )}
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className="lg:col-span-1 lg:my-0">
            <h3 className="text-blue text-2xl mb-4">Academics</h3>
            <div className="flex flex-col">
              <h4 className="bg-teal text-white font-semibold px-4 py-2">
                Program search
              </h4>
              <div className="p-4 bg-teal bg-[rgba(0,95,112,0.05)]">
                <p className="text-xl leading-tight mb-2 font-semibold text-blue">
                  Not sure which academic area your program falls under?
                </p>
                <p className="leading-tight text-base">
                  Use our search tool to find out which drop-in room you should
                  attend.
                </p>
                <Modal
                  opener={
                    <button className="bg-blue text-white uppercase px-4 py-2 mt-4">
                      <strong className="font-semibold">SEARCH</strong> YOUR
                      PROGRAM
                    </button>
                  }
                >
                  <div className="leading-tight">
                    <p className="text-lg leading-tight mb-2 font-semibold text-blue">
                      Find sessions that interest you!
                    </p>
                    <p>
                      Use the search box to filter sessions based on topic or
                      program name. The results will include the title and time
                      of any recommended sessions.
                    </p>
                    <ProgramLookup />
                  </div>
                </Modal>
              </div>
              {Object.keys(groupedAcademics).map(key => (
                <Fragment key={key}>
                  <h4
                    className={classNames(
                      `text-white font-semibold px-4 py-2`,
                      bgColors[key] ? bgColors[key].heading : `bg-blue`
                    )}
                  >
                    {agendaKeyToTimeRange(key)}
                    {key === `900-1600` && (
                      <span>
                        {` `}
                        &mdash; International
                      </span>
                    )}
                  </h4>
                  <div
                    className={classNames(
                      `p-4 flex gap-4`,
                      bgColors[key] ? bgColors[key].box : `white`
                    )}
                  >
                    {groupedAcademics[key].map(node => (
                      <div key={node.jsonId} className="flex-1 w-1/2">
                        <h5 className="text-xl leading-tight mb-2 font-semibold text-blue">
                          {node.title}
                        </h5>
                        <ReactMarkdown className="markdown leading-tight">
                          {node.description}
                        </ReactMarkdown>
                        {node.modalContent && (
                          <Modal
                            opener={
                              <button className="italic underline mt-4">
                                Learn MORE
                              </button>
                            }
                          >
                            <ReactMarkdown className="markdown">
                              {node.modalContent}
                            </ReactMarkdown>
                          </Modal>
                        )}
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Agenda
