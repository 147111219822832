import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment } from "react"
import agendaKeyToTimeRange from "../utils/agenda-key-time-range"
import FlexTable from "./flex-table"

function ProgramLookup(): JSX.Element {
  const [search, setSearch] = React.useState<string>(``)
  const data = useStaticQuery(graphql`
    query PROGRAM_LOOKUP {
      allLookupJson {
        nodes {
          topic
          description
          session {
            title
            joinUrl
            startTime
            endTime
          }
        }
      }
    }
  `)

  const programs = data?.allLookupJson?.nodes.filter(s => s?.session)
  const filteredPrograms = programs.filter(p =>
    `${p.topic}${p.description}${p.session?.title}${agendaKeyToTimeRange(
      `${p?.session?.startTime}-${p?.session?.endTime}`
    )}`.includes(search)
  )
  return (
    <div>
      <input
        value={search}
        type="text"
        placeholder="Search"
        className="w-full border p-2 rounded border-gray-100 my-2"
        onChange={(e): void => {
          setSearch(e.target.value)
        }}
      />
      <FlexTable
        maxHeight={300}
        mobileColumnLabels={false}
        footer={
          <Fragment>
            Showing {filteredPrograms.length} of {programs.length} entries
          </Fragment>
        }
        rows={filteredPrograms.map((program, index) => [
          <Fragment key={index}>{program?.topic}</Fragment>,
          <Fragment key={index}>{program?.session?.title}</Fragment>,
          <Fragment key={index}>
            {agendaKeyToTimeRange(
              `${program?.session?.startTime}-${program?.session?.endTime}`
            )}
          </Fragment>,
          <Fragment key={index}>
            <a
              className="bg-button px-2 py-1 align-baseline text-xs text-white"
              href={program?.session?.joinUrl}
              target="_blank"
              rel="noreferrer"
            >
              Join now
            </a>
          </Fragment>,
        ])}
        className="mt-4"
        headings={[
          {
            label: `Topic`,
            width: `w-3/12`,
          },
          {
            label: `Session`,
            width: `w-3/12`,
          },
          {
            label: `Time (EST)`,
            width: `w-3/12`,
          },
          {
            label: `Join Room`,
            width: `w-3/12`,
          },
        ]}
      />
    </div>
  )
}

export default ProgramLookup
